.Detail__label {
  display: inline-flex;
  background: var(--black);
  color: #fff;
  padding: 8px;
  box-shadow: 8px 8px #d2e9d2;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.Detail__value {
  margin: 0;
  font-size: 40px;
}

.Detail__details {
  color: var(--green);
  font-style: italic;
  font-size: 22px;
  margin-bottom: 12px;
}

.Header {
  position: fixed;
  left: 24px;
  background: rgba(0, 0, 0, 0.8);
  padding: 24px;
  color: white;
  font-family: 'Montserrat', sans-serif;
}

.Header a {
  text-decoration: none;
}
